import Keycloak from 'keycloak-js'

var _kc = new Keycloak('/epl-keycloak.json')
var realm = localStorage.getItem('realm')
// if epl in url
if (realm == 'epl') {
  _kc = new Keycloak('/epl-keycloak.json')
} else if (realm == 'nike') {
  _kc = new Keycloak('/nike-keycloak.json')
} else {
  _kc = new Keycloak('/keycloak.json')
}
export const keyObj = {
  login: undefined,
  isAuthenticated: undefined,
}
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  // _kc = new Keycloak('/nike-keycloak.json')
  var realm = localStorage.getItem('realm')
  console.log(' sdasswrwfwgwrgfgfw', realm)
  if (realm == 'nike') {
    _kc = new Keycloak('/nike-keycloak.json')
  }
  if (realm == 'tranzit') {
    _kc = new Keycloak('/keycloak.json')
  }

  _kc
    .init({
      onLoad: 'check-sso',
      // bearerExcludedUrls: ['/login', '/signup'],
      // initOptions: {
      //   onLoad: 'login-required',
      //   checkLoginIframe: true,
      // },
      bearerExcludedUrls: ['/login', '/signup'],
      // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      // pkceMethod: 'S256',
    })
    .then((authenticated) => {
      UserService.doLogout = _kc.logout
      keyObj.isAuthenticated = authenticated
      if (!authenticated) {
        console.log('user is not authenticated..!')
        // _kc.login()
        // doLogin()
      }
      onAuthenticatedCallback()
    })
    .catch(console.error)
}
const initKeycloakWithLogin = (onAuthenticatedCallback) => {
  var realm = localStorage.getItem('realm')
  console.log(' sdasswrwfwgwrgfgfw', realm)
  if (realm == 'nike') {
    _kc = new Keycloak('/nike-keycloak.json')
  }
  if (realm == 'tranzit') {
    _kc = new Keycloak('/keycloak.json')
  }
  _kc
    .init({
      onLoad: 'check-sso',
      // bearerExcludedUrls: ['/login', '/signup'],
      // initOptions: {
      //   onLoad: 'login-required',
      //   checkLoginIframe: true,
      // },
      bearerExcludedUrls: ['/login', '/signup'],
      // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      // pkceMethod: 'S256',
    })
    .then((authenticated) => {
      UserService.doLogout = _kc.logout

      keyObj.isAuthenticated = authenticated

      if (!authenticated) {
        console.log('user is not authenticated..!')
        _kc.login()
        // doLogin()
      }
      // onAuthenticatedCallback()
    })
    .catch(console.error)
}

const doLogin = _kc.login
// _kc.login = (options) => {
//   options.loginHint = 'some-hint'
//   doLogin(options)
// }
const customLogin = (hint) => {
  let options = { loginHint: '' }
  options.loginHint = hint
  doLogin(options)
}
const doLogout = _kc.logout

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token

const updateToken = (successCallback) => _kc.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = () => _kc.tokenParsed?.preferred_username

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role))
const checkUserRole = (role) =>
  _kc.tokenParsed?.resource_access?.['tranzit-front-end']?.roles?.includes(role)
const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  customLogin,
  _kc,
  checkUserRole,
  keyObj,
  initKeycloakWithLogin,
}

export default UserService
