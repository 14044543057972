import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'
import './i18n'
import HttpService from './services/HttpService'
import UserService from './services/UserService'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import axiosClient from './services/axiosClient'
const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  )
}
renderApp()
// UserService.initKeycloak(renderApp)
// HttpService.configure()
// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById('root'),
//   renderApp,
// )
// HttpService.configure()

// function renderApp(renderAppa) {
//   UserService.initKeycloak(renderAppa)
//   HttpService.configure()
// }

// // const renderApp = () =>
// //   ReactDOM.render(
// //     <React.StrictMode>
// //       <App />
// //     </React.StrictMode>,
// //     document.getElementById('root'),
// //   )
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bit.ly/CRA-PWA
// UserService.initKeycloak(renderApp)
// HttpService.configure()
serviceWorker.unregister()
// <Provider store={store}>
//   <App />
// </Provider>,
// document.getElementById('root'),
// UserService.initKeycloak(renderApp)
// HttpService.configure()
