import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEn from './locales/en/translation.json'
import translationHi from './locales/hi/translation.json'

import LanguageDetector from 'i18next-browser-languagedetector'

const resources = {
  en: {
    translation: translationEn,
  },
  hi: {
    translation: translationHi,
  },
}

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  fallbackLng: 'hi',
})

export default i18n
