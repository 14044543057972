import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = true
  console.log('this', isAuthenticated, restOfProps)

  return (
    <Route
      {...restOfProps}
      render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  )
}
ProtectedRoute.propTypes = {
  component: PropTypes.object,
  restOfProps: PropTypes.object,
}
export default ProtectedRoute
